import React, { useEffect } from 'react'
import type { FC } from 'react'
import classNames from 'classnames'

import type { Props } from './types'
import $ from './styles.module.scss'

export const Modal: FC<Props> = ({
  className,
  open,
  onClose: handleClose,
  children,
}: Props) => {
  const buildClassName = classNames($.modal, { [$.open]: open }, className)

  function toogleBodyScroll(value: boolean): void {
    document.body.style.overflow = value ? 'hidden' : 'auto';
  }

  useEffect(() => {
    toogleBodyScroll(!!open)
  }, [open])

  return (
    <div className={buildClassName}>
      <div className={$.background} onClick={handleClose} />
      <div className={$.cover}>{children}</div>
    </div>
  )
}
