import { Education } from 'components/Education'
import { RequestForm } from 'components/RequestForm'
import React, { FC } from 'react'
import { useLocation } from 'react-router'
import { Button } from '../../components/Button'
import { Typography } from '../../components/Typography'

import { Service as TService, services } from '../../data'

import $ from './styles.module.scss'

function getPrice(value: string): string {
  return value === 'бесплатно'
    ? value
    : new Intl.NumberFormat('ru-RU', {
        style: 'currency',
        currency: 'RUB',
      }).format(Number(value))
}

function getDateByPathname(pathname: string): TService {
  if (pathname === '/protezirovanie') return services.protezirovanie
  if (pathname === '/hirurgiya') return services.hirurgiya
  if (pathname === '/profilaktika') return services.profilaktika
  return services.lechenie
}

export const Service: FC = () => {
  const { pathname } = useLocation()
  const {
    title,
    desc,
    master: { fio, image, skill, stage, since },
    prices,
    education,
  } = getDateByPathname(pathname)

  return (
    <div className="inner-page my-15 mt-sm-4">
      <div className="container flex flex-justify-between block-sm">
        <div className="w30 w100-sm mb-sm-8">
          {image && <img className="w100 mb-10" src={image} />}
          <Typography className="mb-5" size="20" weight="bold">
            {fio}
          </Typography>
          <Typography className="mb-5" weight="300">
            {skill}
          </Typography>
          <Typography className="mb-5" weight="300">
            Стаж работы: {stage} лет.
          </Typography>
          <Typography className="mb-2" weight="300">
            Образование: {since}
          </Typography>
          <Education education={education}>
            <Typography
              className="cur-point mb-10"
              size="14"
              weight="bold"
              color="brand"
            >
              подробнее...
            </Typography>
          </Education>
          {/* <RequestForm
            trigger={<Button variant="big" text="оставить заявку" />}
          /> */}
        </div>
        <div className="w70 ps-15 w100-sm ps-sm-0">
          <Typography className="mb-10 text-center-sm" size="36" weight="bold">
            {title}
          </Typography>
          <Typography className="mb-4" weight="300" align="justify">
            {desc}
          </Typography>
          {prices.map(({ title, price }, index) => (
            <div key={index} className={$.price}>
              <Typography>{title}</Typography>
              <Typography weight="bold">{getPrice(price)}</Typography>
            </div>
          ))}
        </div>
      </div>
      <div className="container">
        <Typography className="mt-10 mb-10" weight="300" align="justify">
          Для точной постановки диагноза и составления плана лечения, требуется
          консультация специалиста. После обследования, беседы с врачом
          составляется план лечения. Стоимость лечения фиксированная. Оплата
          лечения пациентом происходит по прейскуранту клиники на день оказания
          услуг.
        </Typography>
      </div>
    </div>
  )
}
