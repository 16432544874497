import React, { FC } from 'react'
import { Typography } from '../Typography'

export const Footer: FC = () => {
  return (
    <div className="bg-grey-6 py-3">
      <div className="container flex flex-justify-between flex-direction-column-sm">
        <Typography size="10" color="grey-3">
          Ваше белое золото авторская стамотология
        </Typography>
        <Typography size="10" color="grey-3">
          Необходима консультация специалиста.
        </Typography>
        <Typography size="10" color="grey-3">
          © 2022 г. Челябинск. Все права защищены
        </Typography>
      </div>
    </div>
  )
}
