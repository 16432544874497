import { Modal, ModalBody } from 'components/Modal'
import { useModal } from 'components/Modal/hooks'
import { Typography } from 'components/Typography'
import type { Education as TEducation } from 'data'
import React, { FC } from 'react'

import $ from './styles.module.scss'
import { Props } from './type'

export const Education: FC<Props> = ({ children, education }) => {
  const { main, secondary } = education
  const { open, handleOpen, handleClose } = useModal()

  function renderItem(item: TEducation, index: number): JSX.Element {
    const { name, year, type } = item
    return (
      <div key={index} className="mb-8">
        {type !== '' && <Typography className="mb-2">{type}</Typography>}
        <Typography className="mb-2 " weight='bold' size='12'>{year}</Typography>
        <Typography className="mb-2 ">{name}</Typography>
      </div>
    )
  }

  return (
    <>
      <div onClick={handleOpen}>{children}</div>
      <Modal open={open} onClose={handleClose}>
        <ModalBody>
          <div className={`${$.wrapper} py-16 px-12`}>
            <Typography className="mb-8" size='24' weight='bold'>Образование</Typography>
            {main.map((item, index) => renderItem(item, index))}
            <Typography className="mb-8" size='24' weight='bold'>Повышение квалификации</Typography>  
            {secondary.map((item, index) => renderItem(item, index))}
          </div>
        </ModalBody>
      </Modal>
    </>
  )
}
