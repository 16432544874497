import React from 'react'

export const SpriteSVG = (): JSX.Element => (
  <svg style={{ display: 'none' }}>
    <symbol id="close" viewBox="0 0 24 24">
      <path d="M18.2951 7.115C18.6845 6.72564 18.6845 6.09436 18.2951 5.705C17.9057 5.31564 17.2745 5.31564 16.8851 5.705L12.0001 10.59L7.11511 5.705C6.72575 5.31564 6.09447 5.31564 5.70511 5.705C5.31575 6.09436 5.31575 6.72564 5.70511 7.115L10.5901 12L5.70511 16.885C5.31575 17.2744 5.31575 17.9056 5.70511 18.295C6.09447 18.6844 6.72575 18.6844 7.11511 18.295L12.0001 13.41L16.8851 18.295C17.2745 18.6844 17.9057 18.6844 18.2951 18.295C18.6845 17.9056 18.6845 17.2744 18.2951 16.885L13.4101 12L18.2951 7.115Z" />
    </symbol>
    <symbol id="play" viewBox="0 0 10 16">
      <path d="M10 0L0 8L10 16V0Z" />
    </symbol>
  </svg>
)

export type SpriteSVGType = 'close' | 'play'
