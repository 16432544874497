import React from 'react'
import { SpriteSVG } from './shared/sprite'
import { Outlet } from 'react-router-dom'
import { Footer } from './components/Footer'
import { Header } from './components/Header'

function App() {
  return (
    <div className="app">
      <SpriteSVG />
      <Header />
      <Outlet />
      <Footer />
    </div>
  )
}

export default App
