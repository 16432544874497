import { Modal } from 'components/Modal'
import { useModal } from 'components/Modal/hooks'
import React, { FC } from 'react'

import $ from './styles.module.scss'

export const VideoPlayer: FC = () => {
  const {open, handleOpen, handleClose} = useModal()

  return (
    <div className={$.videoPlayer}>
      <div className={$.thumb}>
        <img src="assets/images/thumb.jpg" alt="" />
        <div className={$.button} onClick={handleOpen}>
          <svg width="64" height="44" viewBox="0 0 64 44" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="64" height="44" rx="8" fill="white"/>
            <path d="M29 30L39 22L29 14L29 30Z" fill="black"/>
          </svg>
        </div>
      </div>
      <Modal open={open} onClose={handleClose}>
        {open && (
          <video
            className={$.video}
            width="100%"
            src="assets/video/video.mp4"
            autoPlay
          ></video>
        )}
      </Modal>
    </div>
  )
}
