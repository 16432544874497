import classNames from 'classnames'
import { RequestForm } from 'components/RequestForm'
import React, { FC, useEffect } from 'react'
import { useLocation } from 'react-router'
import { Button } from '../../components/Button'
import { Gallery } from '../../components/Gallery'
import { Typography } from '../../components/Typography'
import { VideoPlayer } from '../../components/VideoPlayer'
import { YandexMap } from '../../components/YandexMap'

import $ from './styles.module.scss'

export const Home: FC = () => {
  const { pathname } = useLocation()

  useEffect(() => {
    const top = pathname === '/contacts' ? 10000 : 0
    window.scrollTo({ top, behavior: 'smooth' })
  }, [pathname])

  return (
    <div>
      <div className={$.about}>
        <div className="container flex block-sm">
          <div className="w50 w100-sm flex-sm flex-direction-column-sm text-center-sm mt-sm-6">
            <Typography
              variant="h2"
              size="36"
              weight="bold"
              text="Добро пожаловать"
            />
            <div className="my-12 my-sm-8">
              <Typography className="mb-8" weight="300">
                Наша команда специалистов поможет Вам подобрать оптимальный
                вариант восстановления утраченного здоровья, используя свой опыт
                работы, современные технологии и качественные материалы.
              </Typography>
              <Typography weight="300">
                После обследования, беседы с врачом составляется план лечения.
                Стоимость лечения фиксированная. Оплата лечения пациентом
                происходит по прейскуранту клиники на день оказания услуг.
              </Typography>
            </div>
            {/* <RequestForm trigger={<Button text="оставить заявку" />} /> */}
          </div>
          <div className="w50 w100-sm my-sm-8">
            <VideoPlayer />
          </div>
        </div>
      </div>
      <Gallery />
      <div id="contacts" className="container flex py-12 block-sm py-sm-8">
        <div className="w50 w100-sm">
          <Typography variant="h2" size="36" weight="bold" text="Контакты" />
          <div className={classNames($.contacts, 'my-12 my-sm-8')}>
            <div className={$.contactsItem}>
              <span>Адресс:</span> Университетская Набережная, 105
            </div>
            <div className={$.contactsItem}>
              <div>
                <span>График работы:</span>
              </div>
              <div>
                <div>пн, ср, пт - с 09:30 до 20:00</div>
                <div>вт, чт - с 09:30 до 18:00</div>
                <div>сб - с 09:00 до 16:00</div>
                <div>вс - выходной</div>
              </div>
            </div>
            <div className={$.contactsItem}>
              <span>Телефон:</span>+7-908-812-17-17
            </div>
            <div className={$.contactsItem}>
              <span>E-mail:</span>beloye_zoloto@bk.ru
            </div>
          </div>
          {/* <RequestForm trigger={<Button text="оставить заявку" />} /> */}
        </div>
        <div className="w50 w100-sm">
          <YandexMap />
        </div>
      </div>
    </div>
  )
}
