export const COLORS = [
  'black',
  'white',
  'grey',
  'grey-1',
  'grey-2',
  'grey-3',
  'grey-4',
  'grey-5',
  'grey-6',
  'brand',
  'info',
  'warning',
  'error',
  'success',
  'link',
] as const

export const FONT_SIZES = [
  '10',
  '12',
  '14',
  '16',
  '18',
  '20',
  '24',
  '36',
] as const

export const FONT_WEIGHT = [
  'bold',
  'bolder',
  'lighter',
  '100',
  '200',
  '300',
  '400',
  '500',
  '600',
  '700',
  '800',
  '900',
] as const
