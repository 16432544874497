import classNames from 'classnames'
import React, { FC, useState } from 'react'
import { NavLink } from 'react-router-dom'

import $ from './styles.module.scss'

const ITEMS = [
  {
    label: 'О нас',
    to: '/',
  },
  {
    label: 'Лечение',
    to: '/lechenie',
  },
  {
    label: 'Протезирование',
    to: '/protezirovanie',
  },
  {
    label: 'Хирургия',
    to: '/hirurgiya',
  },
  {
    label: 'Профилактика',
    to: '/profilaktika',
  },
  {
    label: 'Контакты',
    to: '/contacts',
  },
]

export const Nav: FC = () => {
  const [open, setOpen] = useState<boolean>(false)

  function handleToogleBtn(): void {
    setOpen(!open)
  }

  return (
    <div className={$.menu}>
      <div className={$.btn} onClick={handleToogleBtn}>
        <svg
          width="34"
          height="22"
          viewBox="0 0 34 22"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="34" height="2" />
          <rect y="10" width="34" height="2" />
          <rect y="20" width="34" height="2" />
        </svg>
      </div>
      <div className={classNames($.items, { [$.open]: open })}>
        {ITEMS.map(({ label, to }) => (
          <NavLink
            onClick={handleToogleBtn}
            key={to}
            to={to}
            className={({ isActive }) => {
              return `${$.item} ${isActive ? $.active : `${$.item}`}`
            }}
          >
            {label}
          </NavLink>
        ))}
      </div>
    </div>
  )
}
