import { COLORS, FONT_SIZES, FONT_WEIGHT } from './constants'

export function initGlobalCSS() {
  const colorsStyle = COLORS.reduce((style, color) => {
    return (
      style +
      ` .color-${color} { color: var(--${color})} .bg-${color} { background-color: var(--${color})}`
    )
  }, '')

  const fontSizesStyle = FONT_SIZES.reduce((style, size) => {
    return style + ` .font-size-${size} { font-size: var(--font-size-${size})}`
  }, '')

  const fontWeightsStyle = FONT_WEIGHT.reduce((style, weight) => {
    return (
      style +
      ` .font-weight-${weight} { font-weight: var(--font-weight-${weight})}`
    )
  }, '')

  const styleNode = document.createElement('style')
  styleNode.textContent = colorsStyle + fontSizesStyle + fontWeightsStyle
  document.documentElement.appendChild(styleNode)
}
