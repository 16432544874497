import { useState } from "react"

export function useModal(){
const [open, setOpen] = useState<boolean>(false)


  function handleOpen(): void {
    setOpen(true)
  }

  function handleClose(): void {
    setOpen(false)
  }

  return {
    open,
    handleOpen,
    handleClose,
  }
}