import React, { FC, createElement } from 'react'
import classNames from 'classnames'

import { Props } from './types'

import $ from './styles.module.scss'

export const Typography: FC<Props> = ({
  className,
  variant = 'p',
  size = '16',
  weight = '400',
  color = 'black',
  align,
  text,
  children,
  onClick,
}) => {
  const globalColor = `color-${color}`
  const globalFontSize = `font-size-${size}`
  const globalFontWeight = `font-weight-${weight}`

  const buildClassName = classNames(
    globalColor,
    globalFontSize,
    globalFontWeight,
    align ? $[align] : null,
    className
  )

  const content = text ? text : children

  return createElement(variant, { className: buildClassName, onClick }, content)
}
