import React, { FC } from 'react'

import $ from './styles.module.scss'

export const Logo: FC = () => {
  return (
    <div className={$.wrapper}>
      <svg
        width="42"
        height="58"
        viewBox="0 0 42 58"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.938 26.7854C14.2723 25.0716 8.82945 24.3229 10.2524 18.8104C10.5494 17.6875 10.5641 16.6103 10.1753 15.5995C2.82425 8.35839 2.01891 2.73451 12.6444 0.698834C7.81261 -0.892642 4.03607 0.310369 1.02136 3.53203C-1.63028 7.39981 1.15882 14.1222 6.19009 22.0213C11.3693 32.8859 3.05843 43.0606 13.1075 53.6484C16.1296 56.8326 16.4071 51.8033 14.8434 50.4061C14.8416 51.6084 14.3168 54.0479 13.1333 51.9277C12.7044 50.4305 4.35532 34.6625 16.938 26.7854ZM19.7468 3.16387C19.8711 2.59022 16.7811 2.27878 16.301 1.25394C18.6687 1.54243 21.0393 1.68737 23.424 1.23314C28.8706 -0.178762 33.3281 0.0740399 36.5642 2.21951C41.3797 5.41211 42.6879 9.56011 41.3476 14.2658C40.5851 16.7132 39.2972 18.5531 37.7117 20.0492C35.1482 22.4717 33.8571 25.4917 33.5567 28.9775C31.7639 26.5991 31.8909 23.6325 34.251 21.1074C37.4545 18.4173 39.7918 15.7105 40.1139 12.9643C40.1106 7.99655 38.2549 4.59072 34.0972 3.87699C30.8738 3.32364 27.6528 4.03638 24.6087 4.94724C18.4588 6.62768 14.1555 5.68727 11.1599 2.89062C12.6979 2.58299 17.9002 3.69715 19.7468 3.16387ZM18.1419 21.7408C23.5109 24.0119 28.2788 23.5553 32.4137 20.2298C31.351 22.7038 29.99 24.9698 27.4764 25.2247C22.7348 25.7054 20.2332 23.7133 18.1419 21.7408ZM23.2338 53.3049C23.851 54.0885 24.0502 55.2077 25.0852 55.6555C27.8367 54.9698 33.1469 41.4192 33.6356 31.3314C37.5831 39.6041 31.4894 54.346 26.9508 57.5961C25.8142 58.4543 24.7789 58.3067 23.865 56.9454C23.2291 55.5652 23.0124 54.3493 23.2338 53.3049ZM14.8345 49.1484C15.5245 46.9273 15.9832 43.3632 16.9045 42.4853C16.9759 53.8622 21.5223 54.9533 21.9966 47.6164C19.5796 51.7228 18.7051 45.7766 18.5252 44.8251C18.073 42.6747 17.8976 40.725 18.0622 39.0219C18.1994 37.6646 19.3394 37.9447 19.7466 39.0114C21.6515 42.9133 22.7796 46.9844 23.6552 51.1105C23.3148 45.5496 23.5306 39.981 20.6468 33.8697C19.8754 32.2347 18.5885 32.3014 17.6125 34.1005C14.664 38.6865 14.4829 43.8609 14.8345 49.1484Z"
          fill="#2B2A29"
        />
      </svg>
      <div className={$.info}>
        <div className={$.title}>Ваше Белое Золото</div>
        <div className={$.subtitle}>авторская стоматология</div>
      </div>
    </div>
  )
}
