import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.scss'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { initGlobalCSS } from './utils'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import { Home } from './pages/home'
import { Service } from './pages/service'

initGlobalCSS()

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    children: [
      {
        index: true,
        element: <Home />,
      },
      {
        path: '/lechenie',
        element: <Service />,
      },
      {
        path: '/protezirovanie',
        element: <Service />,
      },
      {
        path: '/hirurgiya',
        element: <Service />,
      },
      {
        path: '/profilaktika',
        element: <Service />,
      },
      {
        path: '/contacts',
        element: <Home />,
      },
    ],
  },
])

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
