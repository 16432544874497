import { Modal } from 'components/Modal'
import React, { FC, useState } from 'react'

import $ from './styles.module.scss'

const IMAGES = ['img-1.jpg', 'img-2.jpg', 'img-3.jpg', 'img-4.jpg', 'img-5.jpg']

export const Gallery: FC = () => {
  const [open, setOpen] = useState<boolean>(false)
  const [index, setIndex] = useState<number>(0)

  function handleClose(): void {
    setOpen(false)
  }

  function setHandleClickImage(idx: number) {
    return () => {
      setIndex(idx)
      setOpen(true)
    }
  }

  function handleClickBtnLeft(): void {
    const idx = index === 0 ? IMAGES.length - 1 : index - 1
    setIndex(idx)
  }

  function handleClickBtnRight(): void {
    const idx = index === IMAGES.length - 1 ? 0 : index + 1
    setIndex(idx)
  }

  return (
    <div className={$.gallery}>
      <div className={$.container}>
        <div className={$.img1} onClick={setHandleClickImage(0)}>
          <img className={$.img} src="assets/images/gallery/img-1.jpg" alt="" />
        </div>
        <div className={$.img2}>
          <img
            className={$.img}
            src="assets/images/gallery/img-2.jpg"
            alt=""
            onClick={setHandleClickImage(1)}
          />
        </div>
        <div className={$.img3}>
          <img
            className={$.img}
            src="assets/images/gallery/img-3.jpg"
            alt=""
            onClick={setHandleClickImage(2)}
          />
        </div>
      </div>
      <div className={$.container}>
        <div className={$.img4}>
          <img
            className={$.img}
            src="assets/images/gallery/img-4.jpg"
            alt=""
            onClick={setHandleClickImage(3)}
          />
        </div>
        <div className={$.img5}>
          <img
            className={$.img}
            src="assets/images/gallery/img-5.jpg"
            alt=""
            onClick={setHandleClickImage(4)}
          />
        </div>
      </div>
      <Modal open={open} onClose={handleClose}>
        <div className={$.imageWrapper}>
          <div className={$.imageBtnLeft} onClick={handleClickBtnLeft}>
            <svg width={10} height={16}>
              <use href="#play" />
            </svg>
          </div>
          <img className={$.image} src={'assets/images/gallery/' + IMAGES[index]} alt="" />
          <div className={$.imageBtnRight} onClick={handleClickBtnRight}>
            <svg width={10} height={16}>
              <use href="#play" />
            </svg>
          </div>
        </div>
      </Modal>
    </div>
  )
}
