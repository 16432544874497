import React, { FC } from 'react'
import { Link } from 'react-router-dom'

import { Logo } from '../logo'
import { Nav } from '../Nav'

import $ from './styles.module.scss'

export const Header: FC = () => {
  return (
    <div className={$.header}>
      <div className={`container ${$.container}`}>
        <Link to="/">
          <Logo />
        </Link>
        <div className={$.phone}>
          <a href="tel:+7351242-02-96">+7 908 812 17 17</a>
        </div>
        <Nav />
      </div>
    </div>
  )
}
